import { axios } from "@/plugins/axios";
import store from "@/store";

let rzp1 = null;
export const getRazorpayKey = () => {
  return axios
    .get("globals/razorpay")
    .then((res) => {
      return Promise.resolve(res.data.key_id);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const generateOrder = (data) => {
  return axios
    .post("payments/order/product", data)
    .then((res) => {
      res.data.product_id = data.product_id;
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const handleClick = ({ user, product_id, quantity = 1 }, ctx) => {
  return create({ user, product_id, quantity, ctx })
    .then(() => {
      if (rzp1) {
        rzp1.open();
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const create = ({ user, product_id, quantity, ctx }) => {
  return generateOrder({ product_id, quantity })
    .then((data) => {
      return getRazorpayKey().then((key_id) => {
        return initiatePayment(data, user, key_id, ctx);
      });
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const handlePaymentFailed = (res, ctx) => {
  console.log("error", res);
  ctx.paymentStatus = "failed";
  ctx.paymentMetadata = res.error.metadata;
  ctx.paymentMessage = res.error.description;
};

const handlePaymentSuccess = (data, product_id, ctx) => {
  // submit to server to be saved in database
  // console.log("data", data);
  return axios
    .post("payments/validate/product/", data)
    .then((res) => {
      console.log("payment validation", res.data);
      ctx.paymentStatus = "success";
      ctx.paymentMetadata = res.data;
      ctx.paymentMessage = res.data.message;
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      console.error("payment validation failed", err);
      ctx.paymentStatus = "validation-failed";
      return Promise.reject(err);
    })
    .finally(() => {
      store.dispatch("auth/fetchUser");
    });
};

export const initiatePayment = (
  { provider_order_id, amount, product_id },
  user,
  key_id,
  ctx
) => {
  // console.log("provider_order_id pre", provider_order_id);
  var options = {
    key: key_id, // Enter the Key ID generated from the Dashboard
    amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: store.getters.getCurrLocale == "hi" ? "विकर्ण" : "Vikarn",
    description:
      store.getters.getCurrLocale == "hi"
        ? "vCoins purchase"
        : "vCoins purchase",
    image: "https://vikarn.com/assets/imgs/logo-vikarn.com.png",
    order_id: provider_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (res) => handlePaymentSuccess(res, product_id, ctx),
    prefill: {
      name: user.username,
      email: user.email,
      contact: user.mobile,
    },
    notes: {
      address: "vikarn.com",
    },
    theme: {
      color: "#1aaa13",
    },
  };

  rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", (res) => {
    handlePaymentFailed(res, ctx);
  });
  rzp1.on("dismiss", () => console.log("dismiss"));
};
