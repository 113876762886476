<template>
  <v-row v-if="order" class="d-flex justify-center align-center">
    <v-col cols="12" md="6">
      <v-img src="/artwork/help.png"></v-img>
    </v-col>
    <v-col cols="12" md="6">
      <v-form ref="form">
        <v-select
          prepend-icon="mdi-frequently-asked-questions"
          v-model="reason"
          :items="items"
          :rules="[(v) => !!v || 'Item is required']"
          label="Choose a reason"
          required
        ></v-select>

        <v-textarea counter label="Other Details" v-model="text"></v-textarea>

        <v-text-field
          prepend-icon="mdi-email"
          :label="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL')"
          :hint="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT')"
          v-model="email"
          type="email"
          :rules="[rules.required, rules.email]"
          :error-messages="errors && errors.email ? errors.email : []"
        ></v-text-field>
        <v-text-field
          prepend-icon="mdi-phone"
          prefix="+91-"
          :label="$lang('MOBILENUMBER')"
          :hint="$lang('10 Digit mobile number without country code.')"
          v-model="mobile"
          type="number"
          :error-messages="errors && errors.mobile ? errors.mobile : []"
          :rules="[rules.max]"
        ></v-text-field>

        <CheckboxRefundAndTerms :ctx="this" />

        <v-btn block :disabled="!agree" color="success" @click="doSubmit">
          Submit
        </v-btn>
      </v-form>
      <v-divider class="my-6"></v-divider>
      <v-card color="transparent" elevation="0">
        <div class="text-center">{{ $lang("Metadata").toUpperCase() }}</div>
        <div class="pa-2">
          <div class="overline">{{ $lang("order") }}:</div>
          <v-spacer></v-spacer>
          {{ order.uid }}
        </div>
        <v-divider></v-divider>
        <div class="pa-2" v-if="authUser && authUser.id">
          <div class="overline">{{ $lang("user") }}:</div>
          <v-spacer></v-spacer>
          {{ authUser.display_name }} ({{ authUser.uid }})
        </div>
        <v-divider></v-divider>
        <div class="pa-2">
          <div class="overline">{{ $lang("amount") }}:</div>
          <v-spacer></v-spacer>
          {{ order.amount / 100 }} {{ order.currency }}
        </div>
        <div class="pa-2">
          <div class="overline">{{ $lang("status") }}:</div>
          <v-spacer></v-spacer>
          {{ order.provider_status }}
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { handleClick } from "../modules/payment.products";
import MyWallet from "../modules/MyWallet.vue";
import CheckboxRefundAndTerms from "../modules/CheckboxRefundAndTerms.vue";

export default {
  name: "payment-help",
  components: {
    // MyWallet,
    CheckboxRefundAndTerms,
  },

  data() {
    return {
      loading: false,
      order: null,
      text: "",
      reason: null,
      email: "",
      mobile: "",
      items: [
        "Payment deducted but coins not credited",
        "Can not purchase product",
        "Other",
      ],
      agree: false,
      rules: {
        required: (value) => !!value || this.$lang("Required"),
        min: (v) => {
          return (
            v.length >= 6 ||
            this.$lang("AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT")
          );
        },
        max: (v) => {
          return [0, 10].includes(v.length) || this.$lang("MobileNumberRule");
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    // isHindi() {
    //   return this.$store.getters.getCurrLocale == "hi";
    // },
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    doSubmit() {
      bus.$emit("showWait");

      return axios
        .post("payments/help/" + this.order.uid, {
          order_uid: this.order.uid,
          text: this.text,
          reason: this.reason,
          agree: this.agree,
          contact_user_id: this.authUser ? this.authUser.id : 0,
          contact_email: this.email,
          contact_mobile: this.mobile,
        })
        .then((res) => {
          this.$router.replace({ name: "my-orders" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchOrder(id) {
      bus.$emit("showWait");
      return axios
        .get("payments/order/" + id)
        .then((res) => {
          this.order = res.data;
        })
        .catch(() => {
          this.$router.replace({ name: "my-orders" });
        })
        .finally(() => {
          bus.$emit("hideWait");
          this.email = this.authUser.email ? this.authUser.email : null;
          this.mobile = this.authUser.mobile
            ? this.authUser.mobile
                .toString()
                .substring(this.authUser.mobile.toString().length - 10)
            : null;
        });
    },
  },
  created() {
    this.fetchOrder(this.$route.params.id);
  },
};
</script>

